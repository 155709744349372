import React from 'react';

export const GreenCheckSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 0C1.34315 0 0 1.34315 0 3V23C0 24.6569 1.34315 26 3 26H23C24.6569 26 26 24.6569 26 23V3C26 1.34315 24.6569 0 23 0H3ZM19.5628 6.45028C20.1205 5.8501 21.0248 5.84972 21.5818 6.45028C22.1394 7.05081 22.1394 8.02472 21.5818 8.62487L11.4856 19.498C11.2069 19.7985 10.8412 19.9486 10.4758 19.9486C10.1104 19.9486 9.74496 19.7981 9.46631 19.498L4.41825 14.0617C3.86058 13.4611 3.86058 12.4876 4.41825 11.887C4.97591 11.2865 5.87988 11.2865 6.43754 11.887L10.4762 16.2363L19.5628 6.45028Z"
      fill="#139982"
    />
  </svg>
);

export default GreenCheckSvg;
