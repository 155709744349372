import React from 'react';
import styled from 'styled-components';

interface LoaderProps {
  className?: string;
  fill?: string;
}

export const Loader: React.FC<LoaderProps> = props => {
  const { className, fill } = props;
  return (
    <AnimatedContainer>
      <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120 60.0037C120 67.8825 118.449 75.6842 115.434 82.9634C112.42 90.2426 108.001 96.8567 102.43 102.428C96.8589 107.999 90.2452 112.419 82.9663 115.434C75.6874 118.449 67.886 120 60.0074 120C51.5873 120 41.6617 119.578 34.9349 113.866C27.2546 107.349 26.1784 96.9955 28.2806 87.6569C29.2329 82.9808 31.3041 78.6051 34.3168 74.9044C37.3296 71.2036 41.1942 68.2881 45.5799 66.4073C49.507 64.8547 53.6973 64.0758 57.9201 64.1135C66.3269 64.1135 71.5217 66.5865 75.799 68.6227C78.7923 70.0477 81.3363 71.2587 84.2174 71.2587C88.9219 71.2587 93.0242 68.5226 93.0242 62.9034C93.0242 53.6318 77.0725 45.2671 58.8346 45.2671C29.2807 45.2671 10.474 64.3105 7.56131 89.1607C3.10874 81.1528 0.549244 72.2315 0.0790613 63.081C-0.391121 53.9305 1.24053 44.7937 4.84888 36.3716C8.45723 27.9495 13.9464 20.4657 20.8955 14.494C27.8445 8.52235 36.0689 4.22153 44.9379 1.92139C53.8068 -0.378752 63.0846 -0.617119 72.0599 1.22457C81.0353 3.06625 89.4697 6.9391 96.7162 12.546C103.963 18.153 109.829 25.3451 113.865 33.5708C117.901 41.7966 120 50.8374 120 60V60.0037ZM49.2475 105.237C54.3679 105.237 58.5188 101.086 58.5188 95.9654C58.5188 90.8448 54.3679 86.6937 49.2475 86.6937C44.127 86.6937 39.9761 90.8448 39.9761 95.9654C39.9761 101.086 44.127 105.237 49.2475 105.237ZM85.4315 95.9645C85.4315 101.085 81.2806 105.236 76.1601 105.236C71.0397 105.236 66.8888 101.085 66.8888 95.9645C66.8888 90.8439 71.0397 86.6929 76.1601 86.6929C81.2806 86.6929 85.4315 90.8439 85.4315 95.9645Z"
          fill={fill ? fill : '#F99746'}
        />
      </svg>
    </AnimatedContainer>
  );
};

const AnimatedContainer = styled.div`
  width: fit-content;
  height: fit-content;
  svg {
    animation-name: loaderspin;
    animation-duration: 2.3s;
    animation-iteration-count: infinite;
    transform-origin: center;
    @keyframes loaderspin {
      0% {
        transform: scale(1) rotate(0deg);
      }

      8% {
        transform: scale(1.1) rotate(0deg);
      }
      12% {
        transform: scale(0.9) rotate(0deg);
      }
      20% {
        transform: scale(1) rotate(0deg);
      }

      40% {
        transform: scale(1) rotate(-90deg);
      }

      60% {
        transform: scale(1) rotate(90deg);
      }

      80% {
        transform: scale(1) rotate(-720deg);
      }
    }
  }
`;
