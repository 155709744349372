import { useMutation } from '@tanstack/react-query';

import backendService from '../services/backendService';
import useRouterMatch from './useRouterMatch';

const useSubmitFeedback = () => {
  const { tenantId } = useRouterMatch();
  const { mutate: submit, isLoading, isSuccess } = useMutation<
    void,
    string,
    { message: string; accepted?: boolean }
  >(({ message, accepted }: { message: string; accepted?: boolean }) =>
    backendService.instance.feedback.submit(message, tenantId, accepted),
  );

  return {
    submit,
    isLoading,
    isSuccess,
  };
};

export default useSubmitFeedback;
