import { Input as AntInput, Tooltip } from 'antd';
import { LiteralUnion } from 'antd/lib/_util/type';
import { joinShortcodes } from 'emojibase';
import data from 'emojibase-data/en/compact.json';
import githubShortCodes from 'emojibase-data/en/shortcodes/github.json';
import EMOTICON_REGEX from 'emojibase-regex/emoticon';
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { CrossSvg, QuestionMarkSvg } from '../../../assets/icons';
import theme from '../../../assets/theme';
import { useMetrics } from '../../../hooks/useMetrics';
import { isCuripodPastedSlide } from '../../../utils/copyAndPasteSlidesUtils';
const emojisList = joinShortcodes(data, [githubShortCodes]);

interface InputProps {
  className?: string;

  disabled?: boolean;
  fluid?: boolean;
  clearable?: boolean;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  label?: string;
  labelStyle?: React.CSSProperties;
  error?: string;
  help?: string;
  id?: string;
  maxLength?: number;
  tooltip?: string;
  borderLess?: boolean;
  autoFocus?: boolean;
  min?: number;
  max?: number;
  icon?: React.ReactElement;
  tabIndex?: number;

  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onPressEnter?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  style?: CSSProperties;
  inputStyle?: CSSProperties;
  type?: LiteralUnion<
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week',
    string
  >;
}

export const Input: React.FC<InputProps> = props => {
  const {
    className,
    disabled,
    fluid,
    label,
    tabIndex,
    error,
    tooltip,
    help,
    placeholder,
    icon,
    clearable,
    value,
    onChange,
    onPressEnter,
    onKeyPress,
    onClear,
    borderLess = false,
    style,
    max,
    min,
    inputStyle,
    type,
    autoFocus,
    labelStyle,
    id,
    maxLength,
    onBlur,
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any>(null);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
    onChange && onChange(e);
    const el = document.activeElement;
    if (el !== ref.current?.input) return;
    findCaretPosition();
  };
  const [inputValue, setInputValue] = useState<string | number>('');
  const metrics = useMetrics();

  const [caretPosition, setCaretPosition] = useState<number>(1);
  //const disabledValue = disabled !== undefined && disabled;
  const fluidValue = fluid !== undefined && fluid;
  const errorValue = error !== undefined;
  const clearableValue = clearable !== undefined && clearable;
  const withIcon = icon !== undefined;

  useEffect(() => {
    if (value === undefined) return;
    if (typeof value === 'number') {
      if (max && value > max) return;
      if (min && value < min) return;
    }
    setInputValue(value);
  }, [max, min, value]);

  const suffixProps =
    clearableValue && value !== undefined && value
      ? { suffix: <StyledClearIcon onClick={onClear} /> }
      : {};

  function findCaretPosition() {
    if (ref && 'current' in ref && ref.current) {
      const textInput = ref.current;
      setCaretPosition(textInput.input.selectionStart || 0);
    }
  }

  useEffect(() => {
    function repositionCaret() {
      if (ref && 'current' in ref && ref.current) {
        const textInput = ref.current;
        if (textInput.input.setSelectionRange) {
          setTimeout(() => {
            textInput.input.setSelectionRange(caretPosition, caretPosition);
            textInput.input.focus();
          }, 0.00001);
          textInput.input.blur();
        }
      }
    }
    const el = document.activeElement;
    if (el !== ref.current?.input) return;
    // Created a synthetic event for the inputElement and dispatches it.
    // The onChange callback will be inoved when the event is dispatched
    // reference for the code: https://newbedev.com/how-do-i-programmatically-trigger-an-input-event-without-jquery
    if (typeof inputValue === 'number') return;
    const emoticon = inputValue.match(EMOTICON_REGEX);
    const emoticonIsFollowedBySpace = emoticon && inputValue.includes(emoticon[0] + ' ');
    if (emoticonIsFollowedBySpace) {
      if (emoticon && emoticon.length) {
        emojisList.forEach(emoji => {
          if (emoji.emoticon && emoji.emoticon === emoticon[0]) {
            const newVal = inputValue.replace(emoticon[0], emoji.unicode);
            const inputRef = ref.current?.input;
            if (inputRef !== null && inputRef !== undefined) {
              const valueSetter = Object.getOwnPropertyDescriptor(inputRef, 'value')?.set;
              const prototype = Object.getPrototypeOf(inputRef);

              const prototypeValueSetter = Object.getOwnPropertyDescriptor(
                prototype,
                'value',
              )?.set;
              if (
                valueSetter &&
                valueSetter !== prototypeValueSetter &&
                prototypeValueSetter
              ) {
                prototypeValueSetter.call(inputRef, newVal);
              } else if (valueSetter) {
                valueSetter.call(inputRef, newVal);
              }
              inputRef.dispatchEvent(new Event('input', { bubbles: true }));
            }
            repositionCaret();
          }
        });
      }
    }
  }, [caretPosition, inputValue, metrics]);

  const handlePaste = useCallback((e: React.ClipboardEvent<HTMLDivElement>) => {
    // Prevent accidentally pasting curipod slide pasted payload into inputs
    const pastedText = e.clipboardData.getData('text');
    if (isCuripodPastedSlide(pastedText)) {
      e.preventDefault();
    }
  }, []);

  return (
    <Wrapper $fluid={fluidValue} style={style} className={className} id={id}>
      <span translate="no">
        {label && (
          <Label style={labelStyle ? labelStyle : {}}>
            {label}{' '}
            {tooltip ? (
              <Tooltip title={tooltip}>
                <HelpIconWrapper>
                  <QuestionMarkSvg height={'20px'} width="10px" />
                </HelpIconWrapper>
              </Tooltip>
            ) : null}
          </Label>
        )}

        <StyledInputWrapper
          onKeyPress={onKeyPress}
          $withIcon={withIcon}
          $borderLess={borderLess}
          $fluid={fluidValue}
          $error={errorValue}
          $clearable={clearableValue}
          ref={ref}
          tabIndex={tabIndex}
          style={inputStyle}
          disabled={disabled}
          prefix={icon}
          placeholder={placeholder}
          value={inputValue}
          max={max}
          min={min}
          type={type}
          onPaste={handlePaste}
          autoFocus={autoFocus}
          onChange={handleChange}
          onPressEnter={onPressEnter}
          maxLength={maxLength}
          onBlur={onBlur}
          {...suffixProps}
        />

        {error && <ErrorLabel>{error}</ErrorLabel>}
        {help && !error && <Label>{help}</Label>}
      </span>
    </Wrapper>
  );
};

interface StyledInputProps {
  $error: boolean;
  $withIcon: boolean;
  $fluid: boolean;
  $clearable: boolean;
  $borderLess: boolean;
}
const HelpIconWrapper = styled.span<{
  height?: string;
  width?: string;
  color?: string;
  cursor?: string;
}>`
  cursor: ${({ cursor }) => (cursor ? cursor : 'pointer')};
  svg {
    height: ${({ height }) => (height ? height : '16px')};
    width: ${({ width }) => (width ? width : '16px')};
    ${({ color }) => color && `color: ${color}`};
  }
`;
const StyledInputWrapper = styled(AntInput)<StyledInputProps>`
  border: ${props => (props.$borderLess ? 'none' : '1px solid #d9d9d9')}; 
  border-radius: 5px;




  ${props =>
    props.$borderLess &&
    css`
      &.ant-input-affix-wrapper {
        box-shadow: 0px 2px 20px rgba(222, 157, 163, 0.15);
        border-radius: 10px;
        border: none;
        padding-left: 25px;
      }
    `}

  &,
  & > input {
    color: ${theme.colors.black};
    font-family: 'Nunito';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 130%;
    padding: 10px 15px;
    mix-blend-mode: normal;
  }

  &:hover {
    border-color: ${theme.colors.green};
    box-shadow: none;
  }

  &:focus {
    background-color: #fff;
    border-color: #d9d9d9;
    box-shadow: none;
  }

  &::placeholder,
  & > input::placeholder {
    color: #a5a5a5;
  }

  input {
    &:focus {
      ::placeholder,
      ::-webkit-input-placeholder {
        color: transparent;
      }
      :-ms-input-placeholder {
        color: transparent;
      }
    }
  }


  ${props =>
    props.$error &&
    css`
      border-color: ${theme.colors.red};

      &:hover,
      &:focus {
        border-color: ${theme.colors.red};
      }
    `}

  ${props =>
    props.$withIcon &&
    css`
      & .ant-input-prefix {
        margin-right: 13px;
      }

      &.ant-input-affix-wrapper-focused {
        background-color: #fbfbfc;
        border-color: #d9d9d9;
        box-shadow: none;

        input {
          background-color: #fbfbfc;
        }
      }

      & .anticon {
        color: #a5a5a5;
      }
    `}

    ${props =>
      props.$fluid &&
      css`
        height: 100%;
      `}
      ${props =>
        props.$clearable &&
        css`
          & .ant-input-suffix {
            margin-right: 10px;
          }
        `}
`;

const StyledClearIcon = styled(CrossSvg)`
  &:hover {
    color: #000000;
  }
`;

interface WrapperProps {
  $fluid: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  ${props =>
    props.$fluid &&
    css`
      height: 100%;
    `}
`;

const Label = styled.label`
  color: #a5a5a5;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
`;

const ErrorLabel = styled.label`
  color: ${theme.colors.red};
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
`;

export const { Search } = AntInput;
