const CuriRocketSvg = () => (
  <svg
    width="466"
    height="461"
    viewBox="0 0 466 461"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M177.618 243.288C177.618 243.288 141.53 255.884 113.059 274.704C84.5872 293.523 60.945 326.817 60.945 326.817C67.2967 324.394 73.7805 322.331 80.3651 320.64C89.09 318.583 94.4428 319.997 94.4428 319.997C94.4428 319.997 65.7344 343.817 48.092 372.82C30.4497 401.822 23.875 436.01 23.875 436.01C23.875 436.01 50.6291 428.398 69.0676 418.111C87.5061 407.824 104.359 390.268 104.359 390.268C103.576 398.781 102.079 407.213 99.8833 415.475C97.448 423.766 94.3369 431.844 90.5813 439.626C90.5813 439.626 144.18 420.772 176.984 382.734C209.788 344.696 224.844 290.519 224.844 290.519L177.618 243.288Z"
      fill="#FFA154"
    />
    <path
      d="M205.893 159.631C205.893 159.631 160.896 157.939 118.976 180.103C77.0556 202.266 38.2143 248.286 38.2143 248.286L89.7489 268.461C89.7489 268.461 102.598 267.13 125.299 260.796C148 254.461 185.078 268.066 185.078 268.066L205.893 159.631Z"
      fill="#C61F13"
    />
    <path
      d="M91.5344 257.647C68.3618 256.586 38.2143 248.286 38.2143 248.286L89.8352 268.341C89.8352 268.341 109.736 264.703 131.785 259.466C139.333 258.726 146.948 259.026 154.415 260.358L162.838 228.413C162.838 228.413 153.663 247.754 131.089 252.616C108.516 257.478 114.706 258.708 91.5344 257.647Z"
      fill="#7E2C26"
    />
    <path
      d="M214.334 312.365L223.792 312.065C223.792 312.065 203.831 355.177 171.148 389.665C138.465 424.153 91.1101 439.541 91.1101 439.541C91.1101 439.541 149.294 408.241 175.196 373.434C201.098 338.627 214.334 312.365 214.334 312.365Z"
      fill="#D84C42"
    />
    <path
      d="M313.319 267.057C313.319 267.057 315.011 312.055 292.847 353.975C270.684 395.895 224.664 434.736 224.664 434.736L204.489 383.202C204.489 383.202 205.82 370.352 212.155 347.651C218.489 324.95 204.885 287.873 204.885 287.873L313.319 267.057Z"
      fill="#C61F13"
    />
    <path
      d="M154.739 284.712C154.739 284.712 147.457 283.493 129.873 290.894C112.288 298.294 87.8762 310.909 87.8762 310.909C87.8762 310.909 97.5028 312.616 104.488 312.214C111.474 311.811 120.809 317.106 120.809 317.106C120.809 317.106 96.1988 334.06 78.6569 359.258C61.115 384.457 42.5662 415.75 42.5662 415.75C42.5662 415.75 60.4737 401.574 74.3005 393.859C88.1272 386.144 126.748 354.685 126.748 354.685C126.748 354.685 119.335 382.887 118.583 391.851C117.831 400.815 110.473 417.576 110.473 417.576C110.473 417.576 130.143 397.79 154.738 369.271C179.333 340.753 185.414 314.553 185.414 314.553C185.414 314.553 178.775 307.358 167.911 296.494C157.047 285.63 154.739 284.712 154.739 284.712Z"
      fill="#FDDA5D"
    />
    <path
      d="M313.319 267.057C313.319 267.057 315.011 312.055 292.847 353.975C270.684 395.895 224.664 434.736 224.664 434.736L207.189 390.025C207.189 390.025 220.738 412.294 237.311 403.884C252.358 396.251 270.307 370.53 280.231 351.96C300.865 313.35 296.924 272.702 296.924 272.702L313.319 267.057Z"
      fill="#7E2C26"
    />
    <path
      d="M455.764 9.48948C478.216 31.9408 458.063 162.896 398.913 222.046C339.763 281.196 240.908 356.999 174.581 290.673C108.255 224.346 185.387 126.803 244.534 67.6554C303.681 8.50802 433.312 -12.9626 455.764 9.48948Z"
      fill="#61B195"
    />
    <path
      d="M432.911 1.47222C432.911 1.47222 449.334 2.49966 456.173 9.89953C458.756 12.4253 461.277 19.0785 462.039 21.7874C464.409 30.2388 465.989 44.033 464.632 63.5689C461.685 106.031 448.276 167.316 404.135 216.723C359.817 264.003 321.063 287.942 297.901 299.381C293.263 301.672 246.135 326.723 209.264 313.013C192.678 306.646 179.158 297.614 169.078 285.355C153.182 266.037 149.543 243.334 149.543 243.334C149.543 243.334 171.944 285.899 208.211 299.378C243.012 312.314 272.118 296.881 297.904 285.352C323.691 273.824 349.908 250.19 369.218 233.655C395.73 210.953 424.933 178.379 443.505 120.841C458.343 74.8685 458.913 26.1389 453.135 15.1696C447.358 4.20025 432.911 1.47222 432.911 1.47222Z"
      fill="#3D725F"
    />
    <path
      d="M247.554 216.337C284.653 253.437 344.804 253.437 381.904 216.337C419.004 179.238 419.004 119.087 381.904 81.9871C344.804 44.8873 284.653 44.8873 247.554 81.9871C210.454 119.087 210.454 179.238 247.554 216.337Z"
      fill="#FFA154"
    />
    <path
      d="M374.183 95.5756C394.472 115.865 402.926 142.917 392.611 153.232C386.365 159.478 378.746 157.964 373.506 152.725C365.728 144.946 368.093 131.42 352.201 115.528C330.896 94.2229 302.322 97.4338 282.709 117.048C263.263 136.493 260.228 164.89 281.533 186.196C297.426 202.088 310.783 199.889 318.562 207.668C323.802 212.908 325.496 220.347 319.072 226.771C308.925 236.918 281.703 228.632 261.415 208.344C225.56 172.485 229.292 125.491 260.231 94.5517C291.493 63.2671 338.326 59.7189 374.183 95.5756Z"
      fill="white"
    />
    <path
      d="M296.827 151.629C298.867 153.669 301.466 155.059 304.295 155.621C307.125 156.184 310.058 155.895 312.723 154.791C315.389 153.687 317.667 151.818 319.27 149.419C320.873 147.02 321.728 144.2 321.728 141.315C321.728 138.43 320.873 135.609 319.27 133.211C317.667 130.812 315.389 128.942 312.723 127.838C310.058 126.734 307.125 126.445 304.295 127.008C301.466 127.571 298.867 128.96 296.827 131C294.091 133.736 292.554 137.446 292.554 141.315C292.554 145.183 294.091 148.894 296.827 151.629Z"
      fill="white"
    />
    <path
      d="M326.778 181.581C328.818 183.621 331.418 185.01 334.247 185.573C337.077 186.135 340.01 185.846 342.675 184.742C345.34 183.638 347.618 181.768 349.221 179.369C350.824 176.97 351.679 174.15 351.679 171.265C351.679 168.38 350.823 165.56 349.221 163.161C347.618 160.763 345.34 158.893 342.674 157.789C340.009 156.685 337.076 156.396 334.246 156.959C331.417 157.522 328.818 158.911 326.778 160.951C325.423 162.306 324.348 163.914 323.615 165.684C322.882 167.454 322.505 169.35 322.505 171.266C322.505 173.182 322.883 175.079 323.616 176.849C324.349 178.618 325.424 180.226 326.778 181.581Z"
      fill="white"
    />
    <path
      d="M91.448 403.726C86.6021 407.732 81.5455 411.476 76.2999 414.943C46.8049 434.433 24.077 435.734 24.077 435.734C24.077 435.734 54.2654 420.927 75.4818 407.411C96.6981 393.895 108.943 381.669 108.943 381.669C108.943 381.669 112.481 386.363 91.448 403.726Z"
      fill="#D84C42"
    />
    <path
      d="M80.7934 320.541C72.7414 322.116 61.3059 326.894 61.3059 326.894C61.3059 326.894 71.7057 317.546 79.7627 315.971C83.8544 315.149 88.0845 315.335 92.0885 316.512C95.3393 317.59 98.4378 319.082 101.307 320.952C101.307 320.952 88.8499 318.97 80.7934 320.541Z"
      fill="#D84C42"
    />
  </svg>
);
export default CuriRocketSvg;
