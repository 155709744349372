import React from 'react';

export const MinusSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.19995 11.2H18.7999C19.4627 11.2 20 10.6627 20 9.99968C20 9.33681 19.4629 8.79956 18.7999 8.79956H1.19995C0.537252 8.79972 0 9.33698 0 9.99984C0 10.6627 0.537252 11.2 1.19995 11.2Z"
      fill="currentColor"
    />
  </svg>
);

export default MinusSvg;
