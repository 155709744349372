import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import backendService from '../services/backendService';
import { useMetrics } from './useMetrics';
import useRouterMatch from './useRouterMatch';
import { tenantCacheKey } from './useTenant';

export const baseCacheKey = 'tenant-subscription';

export type CustomerDoc = {
  customerId: string; // Stripe customer id
  uid: string; // Curipod user id
  tenantId: string; // Curipod tenantId
  country: string; // Stripe customer country eg: NO
  billingEmail: string | null; // email for invoices as stored in stripe
  priceId: string | null; // Stripe price id
  seats: number | null; // Total seats paid for
  subscriptionId: string | null; // Stripe subscription id
  subscriptionStatus: SubscriptionStatus | null; // Stripe subscription status
  subscriptionCancelAtPeriodEnd: boolean | null; // Stripe indicator of plan being cancelled when the current period ends
  subscriptionCancelAt: number | null; // A date in the future at which the subscription will automatically get canceled
  subscriptionCurrentPeriodEnd: number | null; // End of the current period that the subscription has been invoiced for. At the end of this period, a new invoice will be created.
  promoCode?: string | null; // Stripe coupon Id related to a promotionCode used
  couponName?: string | null; // Stripe coupon name related to a promotionCode used
  couponExperiesAt?: number | null; // Stripe coupon experiation date, e.g 50% off Premium for two first months
  priceObj?: any; // Stripe price object
};

// https://stripe.com/docs/api/subscriptions/object
type SubscriptionStatus =
  | 'incomplete'
  | 'incomplete_expired'
  | 'trialing'
  | 'active'
  | 'past_due'
  | 'canceled'
  | 'unpaid';

function useCustomer() {
  const metrics = useMetrics();
  const client = useQueryClient();
  const { tenantId: activeTenantId } = useRouterMatch();
  const { data, isFetching, error } = useQuery(
    [baseCacheKey, activeTenantId],
    () => {
      if (!activeTenantId) return Promise.resolve(undefined);
      return backendService.instance.tenant.getAccount(activeTenantId);
    },
    { staleTime: 1000 * 60 * 5 },
  );

  const {
    mutate,
    data: subscribeData,
    error: subscribeError,
    isLoading: isFetchingSubscribeData,
  } = useMutation(
    ({
      priceId,
      quantity,
      promoCode,
      country,
    }: {
      priceId: string;
      quantity: number;
      promoCode?: string;
      country?: string;
    }) =>
      backendService.instance.tenant.subscribe({
        priceId,
        quantity,
        promoCode,
        country,
        activeTenantId,
      }),
    {
      onSuccess: ({ freePremiumClaimed }, { priceId, promoCode, quantity, country }) => {
        metrics.logEvent('Subscription.Subscribe', {
          freePremiumClaimed,
          priceId,
          promoCode,
          quantity,
          country,
        });
        if (freePremiumClaimed) {
          client.invalidateQueries([tenantCacheKey, activeTenantId]);
          client.invalidateQueries([baseCacheKey, activeTenantId]);
        }
      },
    },
  );

  const {
    mutate: cancelSubscription,
    isLoading: isCancellingSubscription,
    isSuccess: cancelledSubscription,
  } = useMutation(
    () => backendService.instance.tenant.cancelSubscription(activeTenantId),
    {
      onSuccess: () => {
        metrics.logEvent('Subscription.Canceled');
        client.invalidateQueries([baseCacheKey, activeTenantId]);
        setTimeout(() => {
          client.invalidateQueries([baseCacheKey, activeTenantId]);
        }, 5000);
      },
    },
  );

  const { mutate: newCard, data: newCardData } = useMutation(() =>
    backendService.instance.tenant.newCard(),
  );

  const {
    mutate: updateBillingEmail,
    isLoading: isSavingBillingEmail,
    isSuccess: isSavingBillingEmailSuccess,
  } = useMutation(
    ({ email }: { email: string }) => backendService.instance.tenant.updateEmail(email),
    {
      onSuccess: () => {
        client.invalidateQueries([baseCacheKey, activeTenantId]);
        setTimeout(() => {
          client.invalidateQueries([baseCacheKey, activeTenantId]);
        }, 5000);
      },
    },
  );

  const {
    mutate: changeSubscription,
    isLoading: isChangingSubscription,
    isSuccess: changeSubscriptionSuccess,
  } = useMutation(
    ({
      priceId,
      promoCode,
      quantity,
    }: {
      priceId: string;
      promoCode?: string;
      quantity: number;
    }) =>
      backendService.instance.tenant.changeSubscription({
        priceId,
        promoCode,
        quantity,
        activeTenantId,
      }),
    {
      onSuccess: (_, { priceId, promoCode }) => {
        metrics.logEvent('Subscription.Changed', { priceId, promoCode });
        client.invalidateQueries([baseCacheKey, activeTenantId]);
        setTimeout(() => {
          client.invalidateQueries([baseCacheKey, activeTenantId]);
        }, 5000);
      },
    },
  );

  const {
    mutate: previewChangeSubscription,
    isLoading: isLoadingPreviewChangeSubscription,
    data: previewChangeSubscriptionInvoice,
  } = useMutation(
    ({ priceId, quantity }: { priceId: string; quantity: number }) =>
      backendService.instance.tenant.previewChangeSubscription({
        priceId,
        quantity,
        activeTenantId,
      }),
    {
      onSuccess: () => {
        client.invalidateQueries([baseCacheKey, activeTenantId]);
        setTimeout(() => {
          client.invalidateQueries([baseCacheKey, activeTenantId]);
        }, 5000);
      },
    },
  );

  return {
    data,
    isFetching,
    error,
    subscribe: mutate,
    subscribeError: subscribeError,
    subscribeData: subscribeData,
    isFetchingSubscribeData,
    cancelSubscription,
    isCancellingSubscription,
    cancelledSubscription,
    changeSubscription,
    isChangingSubscription,
    changeSubscriptionSuccess,
    newCard,
    newCardRes: newCardData,
    updateBillingEmail,
    isSavingBillingEmail,
    isSavingBillingEmailSuccess,
    previewChangeSubscription,
    isLoadingPreviewChangeSubscription,
    previewChangeSubscriptionInvoice,
  };
}

export default useCustomer;
