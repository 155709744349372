import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useState } from 'react';

import { configureStore } from '../../utils/store';

export const GenericCheckout = ({
  secret,
  children,
}: {
  secret: string;
  children: React.ReactNode;
}) => {
  const [stripePromise] = useState(() =>
    loadStripe(configureStore().config.stripe.publicKey),
  );

  return (
    <>
      <Elements options={{ clientSecret: secret }} stripe={stripePromise}>
        {children}
      </Elements>
    </>
  );
};
