import React from 'react';

export const CrownColoredSvg: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="70"
    height="51"
    viewBox="0 0 70 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.85156 38.4941H60.3535V47.361C60.3535 48.8659 59.1336 50.0858 57.6287 50.0858H12.5764C11.0715 50.0858 9.85156 48.8659 9.85156 47.361V38.4941Z"
      fill="#FFC107"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.6229 7.26459C36.0691 5.9092 34.1497 5.9092 33.596 7.2646L26.0081 25.8362C25.6432 26.7295 24.5879 27.114 23.7338 26.6649L5.85286 17.2623C4.55265 16.5786 3.08052 17.8233 3.53854 19.2191L9.77113 38.2125C9.80174 38.3058 9.82349 38.3996 9.83689 38.4932H60.382C60.3953 38.3996 60.4171 38.3058 60.4477 38.2125L66.6803 19.2191C67.1383 17.8233 65.6662 16.5786 64.366 17.2623L46.4851 26.6649C45.631 27.114 44.5757 26.7295 44.2107 25.8362L36.6229 7.26459Z"
      fill="#FDDA5D"
    />
    <circle cx="35.1059" cy="5.59618" r="5.51219" fill="#FFC107" />
    <circle cx="4.48743" cy="17.671" r="3.62025" fill="#FFC107" />
    <path
      d="M69.3421 17.6339C69.3421 19.6333 67.7212 21.2542 65.7218 21.2542C63.7224 21.2542 62.1016 19.6333 62.1016 17.6339C62.1016 15.6345 63.7224 14.0137 65.7218 14.0137C67.7212 14.0137 69.3421 15.6345 69.3421 17.6339Z"
      fill="#FFC107"
    />
    <circle cx="35.1012" cy="29.6188" r="7.24968" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.4926 29.6178C41.4926 30.457 41.3274 31.288 41.0063 32.0633C40.6852 32.8386 40.2145 33.5431 39.6211 34.1365C39.0277 34.73 38.3233 35.2007 37.548 35.5218C36.7727 35.843 35.9417 36.0082 35.1026 36.0082C34.2057 36.0082 33.1485 35.9633 32.432 35.3549C31.6139 34.6607 31.4993 33.5579 31.7232 32.5632C31.8246 32.0652 32.0453 31.5991 32.3662 31.2049C32.6871 30.8107 33.0987 30.5002 33.5658 30.2998C33.9841 30.1345 34.4304 30.0515 34.8802 30.0555C35.7757 30.0555 36.329 30.3189 36.7846 30.5358C37.1034 30.6876 37.3744 30.8166 37.6812 30.8166C38.1823 30.8166 38.6193 30.5252 38.6193 29.9266C38.6193 28.9391 36.9202 28.0481 34.9776 28.0481C31.8297 28.0481 29.8266 30.0765 29.5163 32.7234C29.0421 31.8705 28.7694 30.9202 28.7194 29.9456C28.6693 28.9709 28.8431 27.9977 29.2274 27.1006C29.6117 26.2036 30.1964 25.4064 30.9366 24.7704C31.6768 24.1343 32.5528 23.6762 33.4974 23.4312C34.4421 23.1862 35.4303 23.1608 36.3863 23.357C37.3423 23.5532 38.2407 23.9657 39.0125 24.5629C39.7844 25.1601 40.4092 25.9262 40.8391 26.8023C41.269 27.6785 41.4926 28.6414 41.4926 29.6174V29.6178ZM33.9568 34.4361C34.5022 34.4361 34.9443 33.9939 34.9443 33.4485C34.9443 32.9031 34.5022 32.4609 33.9568 32.4609C33.4114 32.4609 32.9692 32.9031 32.9692 33.4485C32.9692 33.9939 33.4114 34.4361 33.9568 34.4361ZM37.8114 33.4485C37.8114 33.9939 37.3693 34.4361 36.8239 34.4361C36.2785 34.4361 35.8364 33.9939 35.8364 33.4485C35.8364 32.9031 36.2785 32.4609 36.8239 32.4609C37.3693 32.4609 37.8114 32.9031 37.8114 33.4485Z"
      fill="#F99746"
    />
  </svg>
);

export default CrownColoredSvg;
