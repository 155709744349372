import React from 'react';

export const RedStopSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.1111 0H2.88889C1.3 0 0 1.3 0 2.88889V23.1111C0 24.7 1.3 26 2.88889 26H23.1111C24.7 26 26 24.7 26 23.1111V2.88889C26 1.3 24.7 0 23.1111 0ZM18.7778 14.4444H7.22222C6.42778 14.4444 5.77778 13.7944 5.77778 13C5.77778 12.2056 6.42778 11.5556 7.22222 11.5556H18.7778C19.5722 11.5556 20.2222 12.2056 20.2222 13C20.2222 13.7944 19.5722 14.4444 18.7778 14.4444Z"
      fill="#C61608"
    />
  </svg>
);

export default RedStopSvg;
