import { useQuery } from '@tanstack/react-query';

import backendService from '../services/backendService';
import useMe from './useMe';
import useRouterMatch from './useRouterMatch';

export const baseCacheKey = 'prices';

function usePrices() {
  const { me } = useMe();
  const { tenantId } = useRouterMatch();
  const { data, isFetching, error } = useQuery(
    [baseCacheKey, me],
    () => {
      return backendService.instance.tenant.getPrices(tenantId);
    },
    { staleTime: 1000 * 60 * 60 * 12, enabled: Boolean(me) },
  );

  return {
    prices: data,
    isFetching,
    error,
  };
}

export default usePrices;
