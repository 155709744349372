import React from 'react';

export const DownloadSvg: React.FC<React.SVGProps<SVGSVGElement>> = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0007 12.667H2.00065C1.82384 12.667 1.65427 12.7372 1.52925 12.8623C1.40422 12.9873 1.33398 13.1569 1.33398 13.3337C1.33398 13.5105 1.40422 13.68 1.52925 13.8051C1.65427 13.9301 1.82384 14.0003 2.00065 14.0003H14.0007C14.1775 14.0003 14.347 13.9301 14.4721 13.8051C14.5971 13.68 14.6673 13.5105 14.6673 13.3337C14.6673 13.1569 14.5971 12.9873 14.4721 12.8623C14.347 12.7372 14.1775 12.667 14.0007 12.667Z"
      fill="currentColor"
    />
    <path
      d="M8.00077 1.33301C7.82396 1.33301 7.65439 1.40325 7.52936 1.52827C7.40434 1.65329 7.3341 1.82286 7.3341 1.99967V9.05968L5.14077 6.85968C5.01524 6.73414 4.84497 6.66362 4.66744 6.66362C4.48991 6.66362 4.31964 6.73414 4.19411 6.85968C4.06857 6.98521 3.99805 7.15548 3.99805 7.33301C3.99805 7.51054 4.06857 7.68081 4.19411 7.80634L7.52744 11.1397C7.58941 11.2022 7.66314 11.2518 7.74438 11.2856C7.82562 11.3195 7.91276 11.3369 8.00077 11.3369C8.08878 11.3369 8.17591 11.3195 8.25715 11.2856C8.33839 11.2518 8.41213 11.2022 8.4741 11.1397L11.8074 7.80634C11.8696 7.74418 11.9189 7.67039 11.9525 7.58918C11.9862 7.50796 12.0035 7.42092 12.0035 7.33301C12.0035 7.2451 11.9862 7.15806 11.9525 7.07684C11.9189 6.99563 11.8696 6.92184 11.8074 6.85968C11.7453 6.79752 11.6715 6.74821 11.5903 6.71457C11.509 6.68093 11.422 6.66362 11.3341 6.66362C11.2462 6.66362 11.1591 6.68093 11.0779 6.71457C10.9967 6.74821 10.9229 6.79752 10.8608 6.85968L8.66743 9.05968V1.99967C8.66743 1.82286 8.5972 1.65329 8.47217 1.52827C8.34715 1.40325 8.17758 1.33301 8.00077 1.33301Z"
      fill="currentColor"
    />
  </svg>
);

export default DownloadSvg;
