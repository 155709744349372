import React from 'react';

export const ListColoredSvg: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="84"
    height="76"
    viewBox="0 0 84 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_27087_2429)">
      <path
        d="M6.89062 11.8436C6.89062 9.12431 9.13795 6.91992 11.9102 6.91992H50.7092C53.4814 6.91992 55.7288 9.12431 55.7288 11.8436V61.7721C55.7288 64.4913 53.4814 66.6957 50.7092 66.6957H11.9102C9.13796 66.6957 6.89062 64.4913 6.89062 61.7721V11.8436Z"
        fill="white"
      />
    </g>
    {/* Checkmarks */}
    {[0, 12.166, 24.336, 36.506].map((yOffset, index) => (
      <path
        key={index}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2159 ${15.4617 + yOffset}C22.5365 ${15.7626 + yOffset} 22.5525 ${16.2664 + yOffset} 22.2516 ${16.587 + yOffset}L16.2291 ${23.0044 + yOffset}C15.9288 ${23.3244 + yOffset} 15.4262 ${23.341 + yOffset} 15.1054 ${23.0416 + yOffset}L12.1435 ${20.2772 + yOffset}C11.8221 ${19.9772 + yOffset} 11.8047 ${19.4734 + yOffset} 12.1047 ${19.152 + yOffset}C12.4047 ${18.8306 + yOffset} 12.9085 ${18.8132 + yOffset} 13.2299 ${19.1132 + yOffset}L15.6114 ${21.3359 + yOffset}L21.0906 ${15.4974 + yOffset}C21.3915 ${15.1768 + yOffset} 21.8953 ${15.1608 + yOffset} 22.2159 ${15.4617 + yOffset}Z"
        fill="#61B195"
      />
    ))}
    {/* Text lines */}
    {[
      0,
      2.965,
      5.635,
      12.3164,
      24.4864,
      36.6565,
      15.2832,
      27.4512,
      39.6211,
      18.2478,
      30.4158,
      42.5857,
    ].map((yOffset, index) => (
      <path
        key={`line-${index}`}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3984 ${16.2843 + yOffset}C27.3984 ${15.8997 + yOffset} 27.7102 ${15.5879 + yOffset} 28.0949 ${15.5879 + yOffset}H49.4181C49.8027 ${15.5879 + yOffset} 50.1145 ${15.8997 + yOffset} 50.1145 ${16.2843 + yOffset}C50.1145 ${16.669 + yOffset} 49.8027 ${16.9808 + yOffset} 49.4181 ${16.9808 + yOffset}H28.0949C27.7102 ${16.9808 + yOffset} 27.3984 ${16.669 + yOffset} 27.3984 ${16.2843 + yOffset}Z"
        fill="#CFCFCF"
      />
    ))}
    {/* Pencil and paper elements */}
    <path
      d="M54.6203 61.0733C54.4019 62.295 55.1687 63.8674 57.4399 64.7915C60.1205 65.8822 64.9468 61.7684 64.9468 61.7684C64.9468 61.7684 64.9999 61.7107 68.8381 62.2006C71.4036 62.528 65.3313 59.1532 68.5391 59.9478C70.7172 60.4873 73.9931 62.6105 73.3392 60.8221C72.8246 59.4143 75.9467 61.6104 73.9176 60.003C71.8695 58.3807 70.0713 57.879 67.7831 56.7829C65.2326 55.5611 64.7032 53.3054 61.9826 52.7484C55.7088 51.464 60.2333 52.8798 57.5889 56.8686C56.3556 58.7289 54.9532 59.211 54.6203 61.0733Z"
      fill="#DB5442"
    />
    <path
      d="M55.8046 60.5045C55.5594 61.2894 55.1581 63.4814 57.5521 63.9902C59.9461 64.499 60.1396 63.8774 62.1226 62.3694C64.4098 61.3562 64.5304 61.1015 68.2731 61.5793C70.7748 61.8986 72.2558 62.7694 75.3837 63.5442C77.5076 64.0703 78.7378 62.8264 78.1002 61.0825C77.5984 59.7098 77.242 58.8402 75.2633 57.2729C73.2663 55.6909 71.5128 55.2017 69.2815 54.1329C66.7946 52.9415 65.4388 51.2467 62.7859 50.7036C56.6683 49.4512 63.9654 50.7161 60.8289 54.312C59.4689 55.8711 56.2704 59.0134 55.8046 60.5045Z"
      fill="#F99746"
    />
    <path
      d="M58.7579 47.5913C57.9793 47.3596 55.8112 46.9999 55.3612 49.4397C54.9113 51.8795 57.1125 53.729 58.6408 55.7081L61.5995 57.4228L66.3251 59.67C66.3251 59.67 67.9301 62.9752 68.4083 60.272C69.511 54.0384 68.5767 55.6584 64.9666 52.5542C63.4014 51.2083 60.237 48.0315 58.7579 47.5913Z"
      fill="#F99746"
    />
    <path
      d="M55.5319 47.3105C52.4506 50.566 57.7018 55.9346 58.6335 55.5783C58.6335 55.5783 53.6884 50.2107 56.6541 48.1508C59.6197 46.091 69.097 56.5943 69.097 56.5943C69.097 56.5943 77.123 58.7523 75.1443 57.1849C73.1473 55.603 71.4728 55.7088 69.2415 54.64C66.7546 53.4486 59.2077 43.4269 55.5319 47.3105Z"
      fill="#DB5442"
    />
    <path
      d="M70.1976 59.3471C70.0278 61.2245 68.4811 63.3942 68.4811 63.3942C68.4811 63.3942 73.395 65.6524 75.7603 65.3748C78.5994 65.0416 78.5436 61.9622 78.6608 59.1776C78.7753 56.4555 77.9546 55.0523 75.8843 54.3537C72.6345 53.2572 66.8641 52.4433 66.8641 52.4433C66.8641 52.4433 70.5107 55.8857 70.1976 59.3471Z"
      fill="#61B195"
    />
    <path
      d="M70.1976 59.3471C70.0278 61.2245 68.4811 63.3942 68.4811 63.3942C68.4811 63.3942 73.395 65.6524 75.7603 65.3748C78.5994 65.0416 78.5436 61.9622 78.6608 59.1776C78.7753 56.4555 77.9546 55.0523 75.8843 54.3537C72.6345 53.2572 66.8641 52.4433 66.8641 52.4433C66.8641 52.4433 70.5107 55.8857 70.1976 59.3471Z"
      fill="#61B195"
    />
    <path
      d="M70.1936 59.3458C70.0238 61.2232 68.4771 63.3928 68.4771 63.3928C68.4771 63.3928 73.391 65.6509 75.7563 65.3734C77.9323 65.1181 78.1208 63.3157 78.1208 63.3157C78.1208 63.3157 75.6292 64.7259 73.5589 64.0273C70.3092 62.9308 70.1936 59.3458 70.1936 59.3458Z"
      fill="black"
      fillOpacity="0.33"
    />
    <path
      d="M51.2812 39.8262L44.251 46.0006L67.0236 72.7017C67.3476 73.0816 67.9139 73.123 68.2883 72.7941L73.9626 67.8106C74.337 67.4818 74.3779 66.9071 74.0538 66.5272L51.2812 39.8262Z"
      fill="#33A0DD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.0714 37.8367C41.8247 36.9 42.7506 36.0868 43.6287 36.4689L51.0267 39.6881C51.1186 39.7281 51.2019 39.778 51.2762 39.8359L44.2577 46C44.212 45.917 44.1753 45.8262 44.1495 45.7282L42.0714 37.8367Z"
      fill="#61B195"
    />
    <path
      d="M70.4922 62.3477L63.4619 68.5221L66.9649 72.6293C67.3233 73.0496 67.9502 73.0948 68.3652 72.7303L73.8927 67.8756C74.3077 67.5112 74.3535 66.8751 73.9951 66.4549L70.4922 62.3477Z"
      fill="#FDDA5D"
    />
    <path
      d="M67.5704 71.3166C66.2987 71.3121 44.2922 46.0703 44.2922 46.0703L66.8635 72.5098C67.2788 73.0938 67.8377 73.2379 68.4784 72.6278L73.7772 67.974C74.2554 67.5541 70.3144 71.3264 67.5704 71.3166Z"
      fill="#040404"
      fillOpacity="0.35"
    />
    <path
      d="M61.2597 55.4257L57.9375 54.5483L54.7574 58.9729L54.8467 62.1254L57.6042 64.9644L60.394 64.9065L62.8963 61.5569L63.0956 57.2515L61.2597 55.4257Z"
      fill="#F99746"
    />
    <defs>
      <filter
        id="filter0_d_27087_2429"
        x="0.890625"
        y="0.919922"
        width="60.8359"
        height="71.7754"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_27087_2429"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_27087_2429"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default ListColoredSvg;
