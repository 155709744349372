import { newRelicNoticeError } from '../hooks/useNewRelic';
import { Round } from '../services/backendService/types';
import { generateUniqueId } from './roundUtils';
export type CuripodPasteType<T> = {
  curipodCopySlideVersion: string;
  value: T;
};

type CuripodSlidePasteV1 = {
  rounds: Round[];
  tenantId: string;
};

const supportedVersions = ['1.0', '2.0'];

const toVersionedJSONPayload = <T>(value: T): string => {
  return JSON.stringify({
    curipodCopySlideVersion: '1.0',
    value,
  });
};

export const isCuripodPastedSlide = (pastePayload: string) => {
  try {
    const result = JSON.parse(pastePayload) as CuripodPasteType<unknown>;
    return supportedVersions.includes(result.curipodCopySlideVersion);
  } catch (e) {
    // if any error occurs, we just return false.
    return false;
  }
};

const parseCuripodPastedSlide = <T>(pastePayload: string) => {
  try {
    const result = JSON.parse(pastePayload) as CuripodPasteType<T>;
    return result;
  } catch (e) {
    // if any error occurs, we just return false.
    return undefined;
  }
};

/**
 * Rounds are used to persist the actual data
 * TenantId is needed to handle images compatibility.
 * For now we will not support copy pasting slides across different tenants because we
 * cannot guarantee that images are present in the clipboard.
 * But as long as the image is on the same tenant - it does not matter which game or template
 * the image is related to.
 * This function returns true if writing to clipboard was a success, otherwise false
 */
export const copySlide = async ({
  rounds,
  tenantId,
}: {
  rounds: Round[];
  tenantId: string;
}): Promise<boolean> => {
  if (typeof window === 'undefined') {
    // Prevent SSR side effects
    return false;
  }
  try {
    const generatedPayload = toVersionedJSONPayload({ rounds, tenantId });

    await navigator.clipboard.writeText(generatedPayload);

    return true;
  } catch (e) {
    newRelicNoticeError(e, 'Error while copying a slide');

    // Some error occurred while copying a slide;
    return false;
  }
};

/**
 *
 * @param tenantId used to prevent pasting content across tenants
 */
export const onSlidePasted = async ({ tenantId }: { tenantId: string }) => {
  if (typeof window === 'undefined') {
    // Prevent SSR side effects
    return undefined;
  }

  try {
    const clipboardText = await navigator.clipboard.readText();
    if (isCuripodPastedSlide(clipboardText)) {
      const output = parseCuripodPastedSlide<CuripodSlidePasteV1>(clipboardText);
      if (output?.value.tenantId !== tenantId) {
        // Double check we have the same tenantId due to storage media
        return undefined;
      }

      const newRounds = output.value.rounds
        ? makeNewIdsForRound(output.value.rounds)
        : undefined;

      return newRounds;
    }

    return undefined;
  } catch (e) {
    newRelicNoticeError(e, 'Error while pasting a slide');

    // Some error occurred while copying a slide;
    return undefined;
  }
};

export const makeNewIdsForRound = (relatedRounds: Round[]): Round[] => {
  const newIds = relatedRounds.map(() => generateUniqueId().toString());
  const newRounds = relatedRounds.map((round, i, all) => {
    const refId = round.dataReferenceRoundId
      ? all.findIndex(r => r.id === round.dataReferenceRoundId)
      : undefined;

    const roundOptions = round.roundOptions
      ? round.roundOptions.map(opt => ({ ...opt, id: generateUniqueId().toString() }))
      : undefined;

    return {
      ...round,
      id: newIds[i],
      dataReferenceRoundId: refId !== undefined ? newIds[refId] : null,
      roundOptions,
    };
  });

  return newRounds;
};
