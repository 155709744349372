import React from 'react';

export const ChartColoredSvg: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="53"
    height="54"
    viewBox="0 0 53 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_27087_2422)">
      <path
        d="M45.4661 21.2949H37.7995C37.2911 21.2949 36.8036 21.4969 36.4442 21.8563C36.0847 22.2157 35.8828 22.7033 35.8828 23.2116V46.2116C35.8828 46.7199 36.0847 47.2074 36.4442 47.5669C36.8036 47.9263 37.2911 48.1283 37.7995 48.1283H45.4661C45.9745 48.1283 46.462 47.9263 46.8214 47.5669C47.1809 47.2074 47.3828 46.7199 47.3828 46.2116V23.2116C47.3828 22.7033 47.1809 22.2157 46.8214 21.8563C46.462 21.4969 45.9745 21.2949 45.4661 21.2949Z"
        fill="#33A0DD"
      />
      <path
        d="M30.138 5.96094H22.4714C21.963 5.96094 21.4755 6.16287 21.1161 6.52232C20.7566 6.88176 20.5547 7.36927 20.5547 7.8776V46.2109C20.5547 46.7193 20.7566 47.2068 21.1161 47.5662C21.4755 47.9257 21.963 48.1276 22.4714 48.1276H30.138C30.6464 48.1276 31.1339 47.9257 31.4933 47.5662C31.8528 47.2068 32.0547 46.7193 32.0547 46.2109V7.8776C32.0547 7.36927 31.8528 6.88176 31.4933 6.52232C31.1339 6.16287 30.6464 5.96094 30.138 5.96094Z"
        fill="#F99746"
      />
      <path
        d="M14.8021 28.9609H7.13542C6.62709 28.9609 6.13957 29.1629 5.78013 29.5223C5.42068 29.8818 5.21875 30.3693 5.21875 30.8776V46.2109C5.21875 46.7193 5.42068 47.2068 5.78013 47.5662C6.13957 47.9257 6.62709 48.1276 7.13542 48.1276H14.8021C15.3104 48.1276 15.7979 47.9257 16.1574 47.5662C16.5168 47.2068 16.7188 46.7193 16.7188 46.2109V30.8776C16.7188 30.3693 16.5168 29.8818 16.1574 29.5223C15.7979 29.1629 15.3104 28.9609 14.8021 28.9609Z"
        fill="#61B195"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_27087_2422"
        x="0.21875"
        y="0.960938"
        width="52.1641"
        height="52.168"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.29 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_27087_2422"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_27087_2422"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default ChartColoredSvg;
